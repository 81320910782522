/* eslint-disable @next/next/no-img-element */
import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { trackClickedSubscribeCTA } from '@surfline/web-common';
import WavetrakLink from '../WavetrakLink';
import styles from './PremiumImageOverlayCTA.module.scss';

interface Props {
  imageAlt?: string;
  imageUrl?: string;
  card: {
    name: string;
    button1__url: string;
    button1__text: string;
    button2__url?: string;
    button2__text?: string;
    box__h1: string;
    box__h2: string;
  };
  hostAd?: React.ComponentType | JSX.Element;
  segmentProperties?: Record<string, any>;
  spotName: string;
  cssModules?: {
    ctaContainer?: string;
    ctaGridContainer?: string;
    ctaImageWrapper?: string;
    ctaContent?: string;
    ctaLinks?: string;
    ctaHeading?: string;
    ctaSubheading?: string;
    ctaUpgradeBtn?: string;
    ctaAboutBtn?: string;
  };
}

const PremiumImageOverlayCTA: React.FC<Props> = ({
  imageUrl = null,
  imageAlt = '',
  card,
  cssModules,
  hostAd = null,
  segmentProperties = {},
  spotName = '',
}) => {
  const onClickGoPremium = useCallback(() => {
    if (segmentProperties) {
      trackClickedSubscribeCTA(segmentProperties);
    }
  }, [segmentProperties]);

  const imageUrlSmallSuffixIndex = (imageUrl?.length || 0) - 10;
  const imageUrlProcessed =
    imageUrlSmallSuffixIndex > 0 && imageUrl?.lastIndexOf('_small.jpg') === imageUrlSmallSuffixIndex
      ? `${imageUrl?.substring(0, imageUrlSmallSuffixIndex)}_full.jpg`
      : imageUrl;

  return (
    <div
      className={classNames(styles.ctaContainer, cssModules?.ctaContainer)}
      data-testid="premium-image-overlay-cta"
    >
      <div
        className={classNames(styles.ctaBackgroundImage, 'percy-hide')}
        style={{ backgroundImage: `url(${imageUrlProcessed})` }}
      />
      <div className={styles.cta}>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={2}
          justifyContent="center"
          alignItems="center"
          className={cssModules?.ctaGridContainer}
        >
          <Grid
            item
            mobile={6}
            tablet={5}
            tabletLg={6}
            className={classNames(styles.ctaImageWrapper, cssModules?.ctaImageWrapper)}
          >
            {
              // TODO: Replace <img> with Next.js <Image> but storybook throws an error due an issue
              // with NextJS Image and Storybook introduced in v12.1.5
              // https://github.com/vercel/next.js/issues/36417#issuecomment-1117360509
              // Also, once complete, remove lint exception for entire file
            }
            <img
              src={`${imageUrlProcessed}`}
              alt={imageAlt}
              className={classNames(styles.ctaImage, 'percy-hide')}
            />
            <div className={styles.ctaHostAd}>{hostAd}</div>
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={7}
            tabletLg={6}
            className={classNames(styles.ctaContent, cssModules?.ctaContent)}
          >
            <>
              <Typography
                className={classNames(styles.ctaHeading, cssModules?.ctaHeading)}
                data-testid="premium-image-overlay-cta-heading"
                variant="h4"
              >
                {spotName ? card.box__h1.replace('[spotName]', spotName) : card.box__h1}
              </Typography>
              <Typography
                className={classNames(styles.ctaSubheading, cssModules?.ctaSubheading)}
                variant="caption1"
              >
                {spotName ? card.box__h2.replace('[spotName]', spotName) : card.box__h2}
              </Typography>
              <div className={classNames(styles.ctaLinks, cssModules?.ctaLinks)}>
                <WavetrakLink
                  className={styles.ctaWavetrakLink}
                  href={card.button1__url}
                  isExternal
                  onClick={onClickGoPremium}
                >
                  <Button
                    className={classNames(styles.ctaUpgradeBtn, cssModules?.ctaUpgradeBtn)}
                    variant="primary"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    {card.button1__text}
                  </Button>
                </WavetrakLink>
                {card.button2__url && (
                  <Button
                    className={classNames(styles.ctaAboutBtn, cssModules?.ctaAboutBtn)}
                    href={card.button2__url}
                    variant="primary"
                    color="dark"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    {card.button2__text}
                  </Button>
                )}
              </div>
            </>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PremiumImageOverlayCTA;
